<template>
    <div>
      <v-container>
        <v-row no-gutters>
          <v-col cols="12" md="12">
            <v-card>
              <div>
              <v-btn small class="ma-2" @click="periodassteachEdit()" fab color="warning"><v-icon>mdi-pencil</v-icon></v-btn>
            </div>
              <v-simple-table>
                <tbody>
                  <tr>
                    <td>ครั้งที่</td>
                    <td class="text-center font-weight-bold">
                      {{ periodassteachs.periodAssTeachTimes }}
                    </td>
                    <td>ปี</td>
                    <td class="text-center font-weight-bold">
                      {{  periodassteachs.periodAssTeachYear }}
                    </td>
                  </tr>
                  <tr>
                    <td>เริ่มวันที่</td>
                    <td class="text-center font-weight-bold">
                      {{ periodassteachs.periodAssTeachStart   | moment("add", "543 years")
                        | moment("D MMMM YYYY") }}
                    </td>
  
                    <td>สิ้นสุดวันที่</td>
                    <td class="text-center font-weight-bold">
                      {{ periodassteachs.periodAssTeachStop   | moment("add", "543 years")
                        | moment("D MMMM YYYY") }}
                    </td>
                  </tr>
               
                  <tr>
                    <td>สถานะระบบ</td>
                    <td class="text-center font-weight-bold">
                  <v-chip color="green" dark v-if="periodassteachs.periodAssTeachEnable==='1'">เปิด</v-chip>
                  <v-chip color="red" dark v-else-if="periodassteachs.periodAssTeachEnable==='0'">ปิด</v-chip>
                    </td>
  
                    <td>สถานะ แนบไฟล์</td>
                    <td class="text-center font-weight-bold">
                      <v-chip color="green" dark v-if="periodassteachs.periodAssTeachEnablePro==='1'">เปิด</v-chip>
                  <v-chip color="red" dark v-else-if="periodassteachs.periodAssTeachEnablePro==='0'">ปิด</v-chip>
                
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-card></v-col
          >       
        </v-row>
    
        <!-- V-model editperiodassteachdialog -->
        <v-layout row justify-center>
          <v-dialog v-model="editperiodassteachdialog" persistent max-width="80%">
            <v-card class="mx-auto pa-6">
              <base-material-card
                color="yellow"
                icon="mdi-clipboard-text"
                title="แก้ไขกำหนดการ วิทยาลัยรับรายงานตัวครูผู้ช่วย"
                class="px-5 py-3 text_google"
              ></base-material-card>
              <v-card-text>
                <v-form ref="editperiodassteachform" lazy-validation>
                  <v-container grid-list-md>
                    <v-layout wrap>
                      <v-flex xs12 md6>
                        <v-select
                          v-model="editperiodassteach.periodAssTeachYear"
                          :items="periodassteachselect"
                          item-text="text"
                          item-value="value"
                          label="เลือกปีการศึกษา"
                        >
                        </v-select>
                        <v-spacer></v-spacer>
                      </v-flex>
                      <v-flex xs12 md6>
                        <v-text-field
                          type="number"
                          label="ครั้งที่"
                          v-model="editperiodassteach.periodAssTeachTimes"
                          required
                          :rules="[v => !!v || '']"
                        ></v-text-field>
                      </v-flex>                    
                      <v-flex xs12 md6>
                        <v-text-field
                          type="date"
                          label="วันที่เริ่ม"
                          v-model="editperiodassteach.periodAssTeachStart"
                          required
                          :rules="[v => !!v || '']"
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12 md6>
                        <v-text-field
                          type="date"
                          label="วันที่สิ้นสุด"
                          v-model="editperiodassteach.periodAssTeachStop"
                          required
                          :rules="[v => !!v || '']"
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12 md6>
                        <v-switch
                          v-model="editperiodassteach.periodAssTeachEnable_sw"
                          label="เปิดใช้งานสถานศึกษา"
                        ></v-switch>
                      </v-flex>
  
                      <v-flex xs12 md6>
                        <v-switch
                          v-model="
                            editperiodassteach.periodAssTeachEnablePro_sw
                          "
                          label="เปิดใช้งานประมวลผล"
                        ></v-switch>
                      </v-flex>
                    </v-layout>
                  </v-container>
                  <small>* จำเป็น</small>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  large
                  @click.stop="editperiodassteachdialog = false"
                  rounded
                >
                  <v-icon dark>mdi-close</v-icon>ยกเลิก
                </v-btn>
                <v-btn
                  large
                  color="warning"
                  @click.stop="editperiodassteachSubmit()"
                  rounded
                >
                  <v-icon dark>mdi-pencil</v-icon>&nbsp;บันทึก
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-layout>
      </v-container>
  
      <v-container fluid>
        <v-snackbar
          v-model="snackbar.show"
          multi-line
          vertical
          top
          auto-height
          :timeout="snackbar.timeout"
          :color="snackbar.color"
        >
          <v-icon large>{{ snackbar.icon }}</v-icon>
          <br />
          {{ snackbar.text }}
          <v-btn dark @click="snackbar.show = false">
            Close
          </v-btn>
        </v-snackbar>
      </v-container>
    </div>
  </template>
  <script>
  import VueMoment from "vue-moment";
  import moment from "moment-timezone";
  export default {
    name: "periodassteach",
    data() {
      return {
        VueMoment,
        moment,
        loading: true,
        ApiKey: "HRvec2021",
        valid: true,
        addperiodassteachdialog: false,
        editperiodassteachdialog: false,
        deleteperiodassteachdialog: false,
        snackbar: {
          show: false,
          color: "",
          timeout: 5000,
          icon: "",
          text: ""
        },      
        periodassteachs: [],
        addperiodassteach: {},
        editperiodassteach: {},
        search: "",
        pagination: {},
        headers: [
          { text: "ครั้งที่", align: "center", value: "periodAssTeachTimes" },
          { text: "ปี", align: "center", value: "periodAssTeachYear" },
          { text: "เริ่มวันที่", align: "center", value: "periodAssTeachStart" },
          {
            text: "สิ้นสุดวันที่",
            align: "center",
            value: "periodAssTeachStop"
          },
  
        
          
         
          {
            text: "แก้ไข",
            align: "center",
            value: "actions",
            icon: "mdi-file-document-edit"
          },
          {
            text: "ลบ",
            align: "center",
            value: "action_s",
            icon: "mdi-delete-forever"
          }
        ],
        rowsperpage: [
          25,
          50,
          100,
          {
            text: "All",
            value: -1
          }
        ],
        periodassteachselect: [
          {
            value: "2564",
            text: "2564"
          },
          {
            value: "2565",
            text: "2565"
          },
          {
            value: "2566",
            text: "2566"
          },
          {
            value: "2567",
            text: "2567"
          },
          {
            value: "2568",
            text: "2568"
          },
          {
            value: "2569",
            text: "2569"
          },
          {
            value: "2570",
            text: "2570"
          }
        ],
        data_syslog: {},
        line_topic: []
      };
    },
    async mounted() {
      this.periodassteachQueryAll();
    },
    methods: {
      async periodassteachQueryAll() {
        this.loading = true;
        let result = await this.$http
          .post("periodassteach.php", {
            ApiKey: this.ApiKey,
            periodAssTeachID:"103"
          })
          .finally(() => (this.loading = false));
        this.periodassteachs = result.data;
      },
 
     
         async periodassteachEdit() {
        let result = await this.$http.post("periodassteach.php", {
          ApiKey: this.ApiKey,
          periodAssTeachID:"103"       
        });
        this.editperiodassteach = result.data;
        if (this.editperiodassteach.periodAssTeachEnable == 1) {
          this.editperiodassteach.periodAssTeachEnable_sw = true;
        } else {
          this.editperiodassteach.periodAssTeachEnable_sw = false;
        }
  
        if (this.editperiodassteach.periodAssTeachEnablePro == 1) {
          this.editperiodassteach.periodAssTeachEnablePro_sw = true;
        } else {
          this.editperiodassteach.periodAssTeachEnablePro_sw = false;
        }
        this.editperiodassteachdialog = true;
      },
  
      async editperiodassteachSubmit() {     
        if (this.editperiodassteach.periodAssTeachEnablePro_sw == true) {
          this.editperiodassteach.periodAssTeachEnablePro = "1";   
        } else {
          this.editperiodassteach.periodAssTeachEnablePro = "0";
        }

        if (this.editperiodassteach.periodAssTeachEnable_sw == true) {
          this.editperiodassteach.periodAssTeachEnable = "1";   
        } else {
          this.editperiodassteach.periodAssTeachEnable = "0";
        }


  
        this.editperiodassteach.ApiKey = this.ApiKey;
        let result = await this.$http.post(
          "periodassteach.update.php",
          this.editperiodassteach
        );
  
        
  
        if (result.data.status == true) {
          this.periodassteach = result.data;
  
          Swal.fire({
            icon: "success",
            title: "ดำเนินการบันทึกข้อมูลเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });
  
          this.periodassteachQueryAll();
          let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
          this.data_syslog.ApiKey = this.ApiKey;
          this.data_syslog.user_account = userSession.user_name;
          this.data_syslog.event_log = "update";
          this.data_syslog.page_log = "periodassteach";
          this.data_syslog.table_log = "periodassteach";
          this.data_syslog.date_times = this.date_today_log;
          await this.$http.post("data_syslog.insert.php", this.data_syslog);
        } else {
          Swal.fire({
            icon: "error",
            title: "ดำเนินการผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
        }
        this.editperiodassteachdialog = false;
      },   
 
    },
    computed: {
      pages() {
        if (
          this.pagination.rowsPerPage == null ||
          this.pagination.totalItems == null
        )
          return 0;
  
        return Math.ceil(
          this.pagination.totalItems / this.pagination.rowsPerPage
        );
      },
      date_today_log() {
        let today = new Date();
        let dd = String(today.getDate()).padStart(2, "0");
        let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
        let yyyy = today.getFullYear() + 543;
        let time =
          today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
        today = dd + "/" + mm + "/" + yyyy + "/" + time;
        return today;
      }
    }
  };
  </script>
  